
import "./App.css";
import Merkuron from "./components/merkuron";
function App() {
  return (
   <div>
     <Merkuron/>
   </div>
  );
}

export default App;
